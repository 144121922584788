import React from "react";
import Layout from "../components/_App/layout";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import * as Icon from 'react-feather';
import { Link, graphql } from "gatsby";
import BlogSidebar from '../components/Blog/BlogSidebar';
import { formatDate, getImageURL } from "../format-date";
import SEO from "../components/_App/seo";
const CategoryBlogs = ({ data }) => {
    data.allStrapiBlogs.nodes.forEach(blog => {
        blog.srcset = "";
        blog.sizes = "";
        blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 300)} 300w,`;
        blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 450)} 450w,`;
        blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 500)} 600w,`;
        blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 600)} 600w,`;
        blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 800)} 800w,`;
        blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 1200)} 1200w,`;
        blog.sizes += `(max-width: 1200px) 40vw, 1200px`;
    });
    return (
        <Layout>
            <SEO title={data.strapiBlogCategories.seo_title} description={data.strapiBlogCategories.seo_description} image={getImageURL(data.strapiBlogCategories.seo_image.localFile.name, data.strapiBlogCategories.seo_image.localFile.extension)} type="website" url={`category/${data.strapiBlogCategories.category_slug}/`} />
            <NavbarStyleThree />
            {/* <PageBanner pageTitle={data.strapiBlogCategories.category_name} /> */}

            <div className="blog-area ptb-125">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                {data.allStrapiBlogs.nodes.map(blog => (
                                    <div className="col-lg-6 col-md-6" key={blog.blog_slug + blog.blog_image.url}>
                                        <div className="single-blog-post-box">
                                            <div className="entry-thumbnail">
                                                <Link to={"/" + blog.blog_slug}>
                                                    <img src={getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension)} alt={blog.title}
                                                        srcSet={blog.srcset} sizes={blog.sizes} style={{ "width": "100%", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }}
                                                        loading="lazy" />
                                                </Link>
                                            </div>

                                            <div className="entry-post-content">
                                                <div className="entry-meta">
                                                    <ul>
                                                        <li><Link>{blog.author.author_name}</Link></li>
                                                        <li>{formatDate(blog.created_at,'MMMM DD YYYY')}</li>
                                                    </ul>
                                                </div>

                                                <h3>
                                                    <Link to={"/" + blog.blog_slug}>
                                                        {blog.title}
                                                    </Link>
                                                </h3>
                                                <p>{blog.short_description}</p>

                                                <Link to={"/" + blog.blog_slug} className="learn-more-btn">
                                                    Read Story <Icon.Plus />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                                {/* Pagination */}
                                {/* <div className="col-lg-12 col-md-12">
                                    <div className="pagination-area">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">Prev</Link>
                                                </li>

                                                <li className="page-item active">
                                                    <Link className="page-link" to="#">1</Link>
                                                </li>

                                                <li className="page-item">
                                                    <Link className="page-link" to="#">2</Link>
                                                </li>

                                                <li className="page-item">
                                                    <Link className="page-link" to="#">3</Link>
                                                </li>

                                                <li className="page-item">
                                                    <Link className="page-link" to="#">Next</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
}

export default CategoryBlogs;
export const query = graphql`
query getBlogsByCategorySlug($blog_category_slug:StringQueryOperatorInput) {
    allStrapiBlogs(filter:{blog_categories:{elemMatch:{category_slug:$blog_category_slug}}}){
      nodes {
            title
            short_description
            blog_image {
                localFile {
                    id
                    extension
                    name
                  }
              }
            blog_slug
            author {
              author_name
            }
            created_at
          }
    }
    strapiBlogCategories(category_slug:$blog_category_slug){
        category_name
        seo_title
        seo_image{
          localFile{
            name
            extension
            }
        }
        seo_description
        category_slug
       }
  }
`;